<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row class="d-flex justify-content-around">

        <!-- Search -->
        <b-col
          cols="12"
          md="10"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input

              class="d-inline-block mr-1"
              placeholder="Szukaj..."
            />

          </div>
        </b-col>

        <b-col
          cols="12"
          md="2"
          class="d-flex justify-content-end"
        >
          <b-button
            v-b-modal.modal-lg
            variant="primary"
            class="btn-icon"
          >
            <feather-icon icon="SettingsIcon" />
          </b-button>
        </b-col>

      </b-row>

    </div>

    <b-table
      :items="items"
      :fields="fields"
      responsive
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative"
    >

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-send-icon`"
            icon="SendIcon"
            class="cursor-pointer"
            size="16"
          />
          <b-tooltip
            title="Send Invoice"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-send-icon`"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'apps-invoice-preview', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item>
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Download</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'apps-invoice-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Duplicate</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>

    <b-modal
      id="modal-lg"
      ok-only
      ok-title="Zapisz"
      centered
      size="lg"
      title="Dostosuj wyświetlanie kolumn"
    >

      <div class="d-flex flex-column">

        <b-card-text class="mt-2 mb-2">
          Wybierz kolumny , które mają być wyświetlane na liście
        </b-card-text>

        <div class="mt-1">

          <b-form-checkbox
            id="5"
            :checked="true"
            name="check-button"
            switch
            inline
          >
            <span>ID</span>
          </b-form-checkbox>

          <b-form-checkbox
            id="6"
            :checked="false"
            name="check-button"
            switch
            inline
          >
            <span>Company name</span>
          </b-form-checkbox>

          <b-form-checkbox
            id="7"
            :checked="false"
            name="check-button"
            switch
            inline
          >
            <span>NIP</span>
          </b-form-checkbox>

          <b-form-checkbox
            id="8"
            :checked="false"
            name="check-button"
            switch
            inline
          >
            <span>Email</span>
          </b-form-checkbox>

        </div>

        <div class="mt-1">

          <b-form-checkbox
            id="1"
            :checked="true"
            name="check-button"
            switch
            inline
          >
            <span>Country</span>
          </b-form-checkbox>

          <b-form-checkbox
            id="2"
            :checked="false"
            name="check-button"
            switch
            inline
          >
            <span>Created</span>
          </b-form-checkbox>

        </div>

      </div>

    </b-modal>

  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BFormCheckbox, BTooltip, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BFormCheckbox,
    BTooltip,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          sortable: true,
        },
        {
          key: 'company_name',
          sortable: false,
        },
        {
          key: 'nip',
        },
        {
          key: 'kwota zaległosci',
          sortable: false,
        },
        {
          key: 'created',
          sortable: true,
        },
        {
          key: 'actions',

        },
      ],
      items: [
        {
          id: 4989,
          company_name: 'Company xyz',
          nip: '2378821923096',
          'kwota zaległosci': '1150, 00 pln',
          created: '2021-02-22 10:35',
          country: 'Polska',

        },
        {
          id: 4989,
          company_name: 'Company xyz',
          nip: '2378821923096',
          'kwota zaległosci': '1150, 00 pln',
          created: '2022-02-22 10:35',
          country: 'Polska',

        },
        {
          id: 4989,
          company_name: 'Company xyz',
          nip: '2378821923096',
          'kwota zaległosci': '1150, 00 pln',
          created: '2022-02-22 10:35',
          country: 'Polska',

        },
        {
          id: 4989,
          company_name: 'Company xyz',
          nip: '2378821923096',
          'kwota zaległosci': '1150, 00 pln',
          created: '2022-02-22 10:35',
          country: 'Polska',

        },
        {
          id: 4989,
          company_name: 'Company xyz',
          nip: '2378821923096',
          'kwota zaległosci': '1150, 00 pln',
          created: '2022-02-22 10:35',
          country: 'Polska',

        },

      ],
    }
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
